import React from "react"
import { Link } from "gatsby"
import logo from "./logo.png"

const Layout = props => {
  const { title, children } = props
  const [toggleNav, setToggleNav] = React.useState(false)
  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        <div className="site-head-container">
          <a
            className="nav-burger"
            href={`#`}
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </a>
          <nav id="swup" class="site-head-left">
            <ul className="nav" role="menu">
              <li className="nav-home nav-current" role="menuitem">
                <Link to={`/`}>Home</Link>
              </li>
              <li className="nav" role="menu">
                <Link to={`/about`}>About</Link>
              </li>
            </ul>
          </nav>
          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`}>
              <img src={logo} alt="Dub Ninja" />
            </Link>
          </div>
          <div className="site-head-right">
            <div className="social-links">
              <a
                href="/am"
                title="Apple Music"
                target="_blank"
                rel="noopener noreferrer"
              >
                Apple Music
              </a>
              <a
                href="/spotify"
                title="Spotify"
                target="_blank"
                rel="noopener noreferrer"
              >
                Spotify
              </a>
            </div>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>
      <footer className="site-foot">
        Listen to{" "}
        <a href="/live" target="_blank" rel="noopener noreferrer">
          320 kb/s AAC+ stream
        </a>{" "}
        or{" "}
        <a href="/lossless" target="_blank" rel="noopener noreferrer">
          high-definition lossless
        </a>{" "}
        in your player.
        <br />
        &copy; {new Date().getFullYear()} <Link to={`/`}>Dub Ninja</Link>{" "}
        &mdash; Powered by{" "}
        <a
          href="https://nikitacano.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Nikita Cano
        </a>
      </footer>
    </div>
  )
}

export default Layout
